const ContactPhonePrefix = '+1';

export const numberFormat = (
  num,
  country = 'en-US',
  params = { maximumFractionDigits: 2 }
) => {
  if (num === null || num === undefined || num === '') {
    return '';
  }

  if (typeof num === 'string' && /^[0-9,.\s]+$/.test(num)) {
    return num;
  }

  const formattedToNumber = Number(num);

  if (Number.isNaN(formattedToNumber)) {
    return '';
  }

  return formattedToNumber.toLocaleString(country, params);
};

export const unformatNumber = (num) =>
  num ? parseFloat(num?.replaceAll(',', '')) : null;

export const unformatPrice = (price) =>
  price ? parseFloat(price?.replace(/[^\d.]/g, '')) : null;

export const numberWithCommas = (number = 0) =>
  isNaN(number)
    ? '0'
    : number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const convertKmToMiles = (km = 0) => {
  return Math.round(Number(km) / 1.609);
};

/**
 * Phone number formatter extracted from legacy code.
 *
 * @param {string} value - Non-formatted phone number
 * @returns {string} Formatted phone number
 */
export const formatPhone = (value = '') => {
  if (!value) {
    return value;
  }

  const shouldRemoveFirstDigit = value.includes('+');

  const updatedValue = shouldRemoveFirstDigit
    ? value.replace(/\D/g, '').substring(1)
    : value.replace(/\D/g, '');

  const str = updatedValue
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  return !str[2]
    ? str[1]
    : `(${str[1]}) ${str[2]}${str[3] ? `-${str[3]}` : ''}`;
};

export const normalizePhone = (phone = '') => {
  return phone ? `${ContactPhonePrefix}${phone}` : '';
};

export const phoneValidation = (phone = '') => {
  return cleanPhone(phone)?.toString()?.length === 10 && phone;
};

export const cleanPhone = (phone = '') => {
  return phone.trim().replace(/[^\d]/g, '');
};

export const percentageCalculator = (amount = 0, percentage = 100) =>
  Math.round(amount * (percentage / 100));

const NumberRegex = /^\d+$/;

export const checkNumberString = (str) => NumberRegex.test(str);

export const formatWithCommas = (value) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
