import Text from './Text';
import ButtonIcon from './ButtonIcon';
import Flex from './Flex';
import Select from './Select';
import Loader from './Loader';

const LimitOptions = [10, 25, 50, 100].map((value) => ({
  value,
  text: `${value}`,
}));

const TablePagination = ({
  isLoading = false,
  limit,
  onLimitChange,
  onNextPageClick,
  onPrevPageClick,
  page,
  pageLabel,
  pageStatsText = '',
  total,
}) => {
  const pageStats = pageStatsText
    .replace('{0}', (page - 1) * limit + 1)
    .replace('{1}', Math.min(page * limit, total).toLocaleString())
    .replace('{2}', total.toLocaleString());

  const getLoaderWidth = () => {
    if (!total) {
      return '48px';
    }

    return page > 1 ? '80px' : '72px';
  };

  return (
    <Flex alignItems="center">
      <Text type="headingSubtitle" color="primary" marginRight="15px">
        {pageLabel}
      </Text>
      <Select
        isCompact
        marginRight="27px"
        onChange={onLimitChange}
        options={LimitOptions}
        value={limit}
        inputProps={{ 'aria-label': 'Page limit' }}
      />
      {isLoading ? (
        <Loader type="text" width={getLoaderWidth()} />
      ) : (
        <Text type="headingSubtitle" color="primary" isVisible={total >= 1}>
          {pageStats}
        </Text>
      )}
      <ButtonIcon
        icon="chevronLeft"
        isDisabled={page <= 1}
        onClick={onPrevPageClick}
      />
      <ButtonIcon
        icon="chevronRight"
        isDisabled={total <= page * limit}
        onClick={onNextPageClick}
      />
    </Flex>
  );
};

export default TablePagination;
