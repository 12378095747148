import {
  InspectionUrl,
  MonkInspectionStatus,
  numberFormat,
  Route,
  underscoreToTitleCase,
} from '@portal/lib';

const displayCurrency = (value) => {
  const amount = value || 0;
  return `$${Number(amount).toLocaleString()}`;
};

/** Get offer amount to display to user */
export const getOfferAmount = (singleOffer, rangeOffer) => {
  if (rangeOffer) {
    return rangeOffer
      .split(',')
      .map((val) => (val ? displayCurrency(val) : ''))
      .filter(Boolean)
      .join(' - ');
  }

  return displayCurrency(singleOffer);
};

const DisclosureQuestionType = {
  Boolean: 'boolean',
  Checkbox: 'checkbox',
  Int: 'int',
  Radio: 'radio',
  String: 'string',
};

const DisclosureBooleanType = {
  True: 'Yes',
  False: 'No',
};

/** Generate user friendly disclosure object */
export const getDisclosureList = (questions = [], answers = {}) => {
  const list = questions
    .map((q) => {
      if (shouldSkipQuestion(q)) {
        return null;
      }

      // Parse label
      // Force capitalize each word
      let { label } = q;
      if (label) {
        label = label
          .split(' ')
          .map((w) => {
            try {
              return w[0].toUpperCase() + w.substring(1);
            } catch (err) {
              console.error(err);
              return w;
            }
          })
          .filter(Boolean)
          .join(' ')
          .trim();
      }

      // Parse answer
      let answer = answers[q.key] ?? '';
      if (Array.isArray(answer)) {
        answer = answer.join(', ');
      } else if (typeof answer === 'boolean') {
        answer = answer
          ? DisclosureBooleanType.True
          : DisclosureBooleanType.False;
      }

      answer = answer.trim();

      // Parse sub questions, skip unanswered
      if (q.meta?.parentKey && !answer) {
        return null;
      }

      return { label, answer };
    })
    .filter(Boolean);

  // Replace main question answer with sub question answer
  return list
    .reduce((arr, { label, answer }, index) => {
      if (!label) {
        arr[index - 1].answer = answer;
      }

      return arr;
    }, list)
    .filter(({ label }) => Boolean(label));
};

/**
 * Skip string and int types like vin, make and model. These are natively asked
 * in app
 */
const shouldSkipQuestion = ({ type, key } = {}) => {
  return (
    [DisclosureQuestionType.String, DisclosureQuestionType.Int].includes(
      type
    ) || key === 'color'
  );
};

//create comma separated vehicle info title
export const createVehicleInfoTitle = (detail = []) => {
  return detail.filter(Boolean).join(', ');
};

export const TransmissionDisclosureLabel = 'Transmission';

export const MilesLabel = 'Miles';

export const TransmissionLabel = {
  Automatic: 'Automatic',
  Manual: 'Manual',
};

export const getTransmissionText = (transmission) => {
  const TransmissionMap = {
    A: TransmissionLabel.Automatic,
    Auto: TransmissionLabel.Automatic,
    M: TransmissionLabel.Manual,
  };

  return TransmissionMap[transmission] || transmission;
};

export const getShorterEngine = (engine) => {
  return engine ? engine.split(' ').slice(0, 2).join(' ') : '';
};

// List of all vehicle status, must be kept updated (synced with BE)
export const VehicleStatus = {
  Active: 'active',
  Appointment: 'appointment',
  AppointmentCancelled: 'appointment_cancelled',
  AppointmentFull: 'appointment_full',
  Archived: 'archived',
  BookedInspection: 'booked_inspection',
  BookedVisit: 'booked_visit',
  ClosedLost: 'closed_lost',
  ClosedWon: 'closed_won',
  Contacted: 'contacted',
  Deleted: 'deleted',
  DeployedCompliance: 'deployed_compliance',
  Dismissed: 'dismissed',
  Disqualified: 'disqualified',
  Dormant: 'dormant',
  FollowUp: 'follow_up',
  HumanAppraised: 'human_appraised',
  Incomplete: 'incomplete',
  InspectionCompleted: 'inspection_completed',
  InspectionRequested: 'inspection_requested',
  MachineAppraised: 'machine_appraised',
  New: 'new',
  Purchased: 'purchased',
  Qualified: 'qualified',
  ScheduledAppointment: 'scheduled_appointment',
  ScheduledInspection: 'scheduled_inspection',
  SentToCrm: 'sent_to_crm',
  Unavailable: 'unavailable',
};

const VehicleStatusColorMap = {
  [VehicleStatus.Active]: 'success',
  [VehicleStatus.Appointment]: 'warning',
  [VehicleStatus.BookedVisit]: 'info',
  [VehicleStatus.ClosedLost]: 'gray',
  [VehicleStatus.ClosedWon]: 'gray',
  [VehicleStatus.Dismissed]: 'gray',
  [VehicleStatus.Disqualified]: 'gray',
  [VehicleStatus.Dormant]: 'info',
  [VehicleStatus.FollowUp]: 'warning',
  [VehicleStatus.New]: 'success',
  [VehicleStatus.SentToCrm]: 'success',
  [VehicleStatus.Unavailable]: 'gray',
};

// Filter out deprecated statuses
// Status must be returned in GQL (see VehicleSearchOutputStatusFields)
const VehicleStatusMainIncludeList = [
  VehicleStatus.Active,
  VehicleStatus.ClosedLost,
  VehicleStatus.ClosedWon,
  VehicleStatus.FollowUp,
  VehicleStatus.BookedVisit,
];

const VehicleStatusLegacyList = [
  VehicleStatus.Dismissed,
  VehicleStatus.Disqualified,
  VehicleStatus.Dormant,
  VehicleStatus.SentToCrm,
  VehicleStatus.Unavailable,
];

export const getVehicleStatus = (status, customColor = {}) => {
  const text = underscoreToTitleCase(status);
  const initialColor = VehicleStatusColorMap[status];

  // Use custom, mapped, or default color
  const color = customColor[initialColor] || initialColor || 'gray';

  return { color, text };
};

// Generate button group options from enum. Filter out current status.
export const getStatusOptions = (status, { colorMap = {} } = {}) => {
  let list = Object.values(VehicleStatus)
    .map((value) => {
      const { text, color } = getVehicleStatus(value, colorMap);

      return {
        color,
        text,
        value,
      };
    })
    .filter(({ value }) => value !== status);

  // Display as options only the included list
  if (VehicleStatusMainIncludeList.length) {
    list = list.filter(({ value }) =>
      VehicleStatusMainIncludeList.includes(value)
    );
  }

  return list;
};

const DefaultStatusIncludeList = [
  ...VehicleStatusMainIncludeList,
  ...VehicleStatusLegacyList,
];

// Only reset what is being provided as options
export const DefaultStatusFlags = Object.values(VehicleStatus).reduce(
  (obj, status) => {
    if (DefaultStatusIncludeList.length) {
      return DefaultStatusIncludeList.includes(status)
        ? { ...obj, [status]: false }
        : obj;
    }

    return { ...obj, [status]: false };
  },
  {}
);

const getGenericDisclosureText = (value) => {
  if (Array.isArray(value)) {
    return value.join('\n');
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'None';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }

  return;
};

export const getDisclosureComparisonTexts = ({ label, origin, target }) => {
  let originText = '';
  let targetText = '';

  if (label === TransmissionDisclosureLabel) {
    originText = getTransmissionText(origin);
    targetText = getTransmissionText(target);
  } else if (label === MilesLabel) {
    originText = numberFormat(origin);
    targetText = numberFormat(target);
  } else {
    originText = getGenericDisclosureText(origin);
    targetText = getGenericDisclosureText(target);
  }

  return { originText, targetText };
};

export const checkCompletedInspection = (list) =>
  list?.some(
    ({ monk_inspection_status }) =>
      monk_inspection_status === MonkInspectionStatus.Done
  );

export const getInspectionRedirectUrl = (vehicleUuid) =>
  `${InspectionUrl}?uuid=${vehicleUuid}`;

export const getStandaloneDisclosureUrl = (appraisalUuid) =>
  `${Route.disclosureAdd.path}?uuid=${appraisalUuid}`;

export const getQuickQuoteThankYouUrl = (vehicleUuid) =>
  Route.vehicleThankyou.path.replace('[vehicle_uuid]', vehicleUuid);
