import { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';

import Icon from './Icon';

const ButtonIcon = forwardRef(
  ({ icon, isDisabled = false, color, size, ...props }, ref) => (
    <IconButton
      {...props}
      disabled={isDisabled}
      ref={ref}
      aria-label={`Perform ${icon} action`}
    >
      <Icon icon={icon} color={isDisabled ? 'disabled' : color} size={size} />
    </IconButton>
  )
);

export default ButtonIcon;
