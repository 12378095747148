import { useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import dynamic from 'next/dynamic';

import {
  AppLayout,
  useUser,
  UserContextProvider,
  NotificationContextProvider,
} from '@portal/app';
import { ThemeProvider } from '@portal/ui';
import {
  useAlert,
  useAuth,
  useClient,
  useLocale,
  usePreviousPath,
  useToken,
} from '@portal/hooks';
import {
  atsFireEvent,
  atsInit,
  configureYup,
  Event,
  PublicRoutes,
  Route,
  RoutesWithoutAuth,
  RoutesWithoutNavBar,
  RoutesWithSideBar,
} from '@portal/lib';

const NotificationPoll = dynamic(
  () => import('@portal/notifications').then((mod) => mod.NotificationPoll),
  { ssr: false }
);
const Alert = dynamic(() => import('@portal/ui').then((mod) => mod.Alert), {
  ssr: false,
});
const Toaster = dynamic(
  () => import('react-hot-toast').then((mod) => mod.Toaster),
  { ssr: false }
);

import '@portal/styles/index.scss';

const App = ({ Component, pageProps }) => {
  configureYup();
  usePreviousPath();

  const router = useRouter();
  const { locale } = useLocale();
  const { alert, clearAlert } = useAlert();

  useEffect(() => {
    atsInit();
  }, []);

  const { isAuthenticatedRoute, isPublicRoute } = useMemo(() => {
    const isPublicRoute = PublicRoutes.includes(router.route);
    const isNoAuthRoute = RoutesWithoutAuth.includes(router.route);
    return {
      isAuthenticatedRoute: !isNoAuthRoute,
      isPublicRoute,
    };
  }, [router.route]);

  // Retrieve token
  const { token, userId, clearToken, saveToken } = useToken();

  // Validate token
  const { redirectToLogin, redirectToSavedPath } = useAuth({
    isAuthenticatedRoute,
    saveToken,
    token,
  });

  // Initialize graphql client
  const client = useClient({ token, isPublicRoute });

  // Retrieve user
  const user = useUser({
    client,
    isPublicRoute,
    onError: redirectToLogin,
    onSuccess: redirectToSavedPath,
    userId,
  });

  const storeId = user.data?.meta.currentStore?.id;

  useEffect(() => {
    if (router?.pathname && router?.pathname !== '/' && storeId) {
      atsFireEvent(Event.ViewPage, { storeId });
    }
  }, [router?.asPath, router?.pathname, storeId]);

  const navigationItems = [
    {
      text: locale.appNavLeads,
      path: Route.leadList.path,
      display: true,
    },
    {
      text: locale.appNavAppointments,
      path: Route.appointmentList.path,
      display: user.data?.meta?.hasManagedStores,
    },
    {
      text: locale.appNavAppraisals,
      path: Route.appraisalList.path,
      display: user.data?.meta?.hasAppraisalStores,
    },
  ];

  const handleNavigationItemClick = ({ path }) => router.push(path);
  const handleLogoClick = () => router.push(Route.overview.path);

  const hasSideBar =
    router?.pathname && RoutesWithSideBar.includes(router?.pathname);
  const hasNavBarHidden =
    router?.pathname && RoutesWithoutNavBar.includes(router?.pathname);
  const isRedirectingToLogin = !token && isAuthenticatedRoute;
  const isAuthenticated = Boolean(token) && isAuthenticatedRoute;
  const isInitializing =
    !client || user.isLoading || (isAuthenticatedRoute && !user.data);

  return (
    <ThemeProvider>
      <UserContextProvider value={user.data}>
        <NotificationContextProvider>
          <AppLayout
            activePath={router?.pathname || ''}
            hasNavBar={!hasNavBarHidden}
            hasSideBar={hasSideBar}
            isAuthenticated={isAuthenticated}
            isInitializing={isInitializing}
            isRedirectingToLogin={isRedirectingToLogin}
            navigationItems={navigationItems}
            onLogoClick={handleLogoClick}
            onLogoutClick={clearToken}
            onNavigationItemClick={handleNavigationItemClick}
          >
            <ApolloProvider client={client}>
              {isAuthenticated && <NotificationPoll />}
              <Component {...pageProps} user={user.data} />
            </ApolloProvider>
            {alert.message && (
              <Alert
                type={alert.type}
                message={alert.message}
                onClose={clearAlert}
              />
            )}
            <Toaster position="bottom-right" reverseOrder />
          </AppLayout>
        </NotificationContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default App;
