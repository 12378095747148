import {
  Box,
  ButtonBase,
  ButtonIcon,
  IconAddCameraV2,
  IconExpandMore,
  Image,
  Text,
  IconRefresh,
  IconDone,
} from '@drivably-apps/component-lib';

import {
  caseInsensitive,
  DateFormats,
  diffDate,
  exportFilterDate,
  exportFilterStatus,
  exportFilterValidValue,
  handleDate,
  VehicleStatus,
  underscoreToTitleCase,
  formatCurrency,
  addDaysToDate,
  getExpiredStatus,
  numberFormat,
} from '@portal/lib';
import LeadListStatusWithDropdown from './LeadListStatusWithDropdown';
import LeadListSchedule from './LeadListSchedule';
import styled from '@emotion/styled';
import { disabledPhotoCaptureStoreIds, SourcePlatform } from './utils';
import LearnMore from './LearnMore';

let isGuaranteedPriceEnabled = false;
export const getLeadListColumns = ({
  hasSearchTerm,
  hideStoreCol,
  locale,
  onCustomCellClick,
  onExpandMoreClick,
  onInspectButtonClick,
  onThumbnailImageClick,
  onLearnMoreClick,
  enable_consumer_guaranteed_price,
  guaranteeEnabledStores,
}) => {
  const columns = [
    {
      field: 'created_at',
      headerName: locale.leadTableCreatedAt,
      comparator: diffDate,
      filter: exportFilterDate,
      filterParams: { locale },
      cellRenderer: ({ data }) => {
        const { created_at: createdAt, lead_viewed } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            direction="column"
            align="center"
            onClick={() => onCustomCellClick(data)}
          >
            <TextComponent size="xs" weight={weight}>
              {handleDate(createdAt, DateFormats.mmmm_d_yyyy)}
            </TextComponent>
            <TextComponent size="xs" weight={weight}>
              {handleDate(createdAt, DateFormats.h_mm_A)}
            </TextComponent>
          </Box>
        );
      },
    },
    {
      field: 'vehicle',
      headerName: locale.leadTableVehicle,
      maxWidth: 70,
      sortable: false,
      cellRenderer: ({ data }) => {
        const { vehicle: imageSrc, uuid, store__name, store__id } = data;

        return (
          <Box isFlex justify="center" align="center" width="full">
            {imageSrc ? (
              <ButtonBase onClick={() => onThumbnailImageClick(imageSrc)}>
                <Image
                  alt={imageSrc}
                  height="45px"
                  isCover
                  src={imageSrc}
                  width="45px"
                  radius="md"
                />
              </ButtonBase>
            ) : (
              <ButtonIcon
                isDisabled={disabledPhotoCaptureStoreIds.has(store__id)}
                backgroundColor="primary"
                onClick={() => onInspectButtonClick(uuid, store__name)}
                aria-label="Add photo"
              >
                <IconAddCameraV2 />
              </ButtonIcon>
            )}
          </Box>
        );
      },
    },
    {
      field: 'vehicle__info',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 250,
      cellRenderer: ({ data }) => {
        const { year, make, model, vin, mi, lead_viewed, trim } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            isFlex
            direction="column"
            width="300"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              size: 'md',
              content: `${year || ''} ${make || ''} ${model || ''} ${trim || ''}`,
            })}
            {vin &&
              RenderTextComponent({
                size: 'sm',
                weight,
                content: `${locale.leadTableVIN}: ${vin}`,
              })}
            {mi &&
              RenderTextComponent({
                size: 'sm',
                weight,
                content: `${locale.vehicleDetailStockMileage}: ${numberFormat(mi)} ${locale.vehicleLeadMiles}`,
              })}
          </Box>
        );
      },
    },
    {
      field: 'consumer_price',
      headerName: locale.leadTableConsumerPrice,
      minWidth: 150,
      comparator: caseInsensitive,
      cellRenderer: ({ data }) => {
        const { sourced_by, consumer_price } = data;
        const weight = 'normal';
        const modSource =
          sourced_by === SourcePlatform.Amazon ? 'Amazon' : sourced_by;
        return (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${consumer_price ? formatCurrency(consumer_price) : '-'}`,
            })}
            {RenderTextComponent({
              weight,
              content: `Source: ${sourced_by ? underscoreToTitleCase(modSource) : '-'}`,
            })}
          </Box>
        );
      },
    },
    {
      field: 'guaranteed_price',
      headerName: locale.leadTableGuaranteePrice,
      minWidth: 150,
      comparator: caseInsensitive,
      cellRenderer: ({ data }) => {
        const { dealer_guaranteed_price, dealer_guaranteed_accepted_date } =
          data;
        const isAmazonLead = data.sourced_by === SourcePlatform.Amazon;
        const isGpLead = data.sourced_by === SourcePlatform.GuaranteedPrice;
        const dateCreatedAt =
          enable_consumer_guaranteed_price || isAmazonLead || isGpLead
            ? data?.app_created_at
            : data.created_at;

        const expiryDate = handleDate(
          addDaysToDate(
            dateCreatedAt,
            data?.isDealerAppraisal && isAmazonLead ? 3 : 7
          ),
          DateFormats.mm_dd_yy
        );
        const weight = 'normal';

        const isExpired = getExpiredStatus({
          date: dateCreatedAt,
          isAmazonLead,
          hasDealerPrice: data?.isDealerAppraisal,
          acceptedDate: dealer_guaranteed_accepted_date,
        });
        const guaranteeStatus = dealer_guaranteed_accepted_date
          ? locale.vehicleOfferAccepted
          : isExpired
            ? locale.statusExpired
            : null;

        const isDealerGuaranteeEnabled = guaranteeEnabledStores?.includes(
          data?.store__id
        );

        isGuaranteedPriceEnabled =
          isAmazonLead || isDealerGuaranteeEnabled || isGpLead;

        return isGuaranteedPriceEnabled ? (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${dealer_guaranteed_price ? formatCurrency(dealer_guaranteed_price) : '-'}`,
            })}
            {RenderTextComponent({
              weight,
              content: `Exp: ${dealer_guaranteed_price && expiryDate ? expiryDate : '-'}`,
            })}
            {guaranteeStatus && (
              <LeadStatus locale={locale} status={guaranteeStatus} />
            )}
          </Box>
        ) : (
          <Box isFlex direction="column" onClick={onLearnMoreClick}>
            <LearnMore />
          </Box>
        );
      },
    },
    {
      field: 'contact__name',
      headerName: locale.leadTableCustomerInfo,
      comparator: caseInsensitive,
      flex: 0.5,
      minWidth: 200,
      maxWidth: hideStoreCol ? 350 : 450,
      cellRenderer: ({ data }) => {
        const { contact__email, contact__name, contact__mobile, lead_viewed } =
          data;
        const weight = lead_viewed ? 'normal' : 'bold';
        const isAmazonLead = data.sourced_by === SourcePlatform.Amazon;

        return isAmazonLead ? (
          <Text>--</Text>
        ) : (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${contact__name || ''}`,
            })}
            {RenderTextComponent({
              weight,
              content: `${contact__mobile || ''}`,
            })}
            {RenderTextComponent({
              weight,
              content: `${contact__email || ''}`,
            })}
          </Box>
        );
      },
    },
    {
      field: 'store__name',
      headerName: locale.leadTableStore,
      flex: 0,
      minWidth: 150,
      maxWidth: 250,
      hide: hideStoreCol,
      comparator: caseInsensitive,
      cellRenderer: ({ data }) => {
        const {
          store__name,
          store__address,
          store__city,
          store__state,
          lead_viewed,
        } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${store__name || ''}`,
            })}
            {store__address &&
              RenderTextComponent({
                weight,
                content: `${store__address}`,
              })}
            {store__city &&
              store__state &&
              RenderTextComponent({
                weight,
                content: `${store__city || ''} ${store__state && ','} ${
                  store__state || ''
                }`,
              })}
          </Box>
        );
      },
    },
    {
      field: 'status__updated_at',
      sortable: false,
      headerName: locale.leadTableStatus,
      minWidth: 150,
      maxWidth: 250,
      cellRenderer: ({ data }) => {
        const { id, meeting, status } = data ?? {};
        if (!id || !status) {
          return null;
        }

        const meetingVehicleId = meeting?.vehicle_id;

        return (
          <Box isFlex flexGap="sm">
            {status.map((statusText) => (
              <LeadListStatusWithDropdown
                hasSearchTerm={hasSearchTerm}
                id={id}
                key={statusText}
                status={statusText}
                pastMeetingVehicleId={Boolean(meetingVehicleId)}
              />
            ))}
          </Box>
        );
      },
      filter: exportFilterStatus,
      filterParams: { locale },
    },
    {
      field: 'meeting',
      sortable: false,
      headerName: locale.leadTableScheduleDateTime,
      cellRenderer: ({ data }) => {
        const { id, meeting, status = [] } = data;
        const scheduleDate = meeting?.date;
        const meetingVehicleId = meeting?.vehicle_id;
        const isStatusAppointment = status.some((val) =>
          [VehicleStatus.BookedVisit].includes(val)
        );

        return (
          <LeadListSchedule
            hasSearchTerm={hasSearchTerm}
            id={id}
            isStatusAppointment={isStatusAppointment}
            scheduleDate={scheduleDate}
            pastMeetingVehicleId={meetingVehicleId}
          />
        );
      },
    },
    {
      field: 'actions',
      minWidth: 100,
      cellRenderer: ({ data }) => {
        const { id } = data;

        return (
          <Box isFlex>
            <ButtonIcon
              onClick={() => onExpandMoreClick?.(id)}
              aria-label="Expand more"
            >
              <IconExpandMore />
            </ButtonIcon>
          </Box>
        );
      },
    },
  ];

  // Hide/Show ACV GuaranteedPrice based on flag
  const filteredColumns = columns.filter(
    (row) => row.headerName !== locale.leadTableGuaranteePrice
  );
  return enable_consumer_guaranteed_price ? columns : filteredColumns;
};

export const getAdditionalLeadListColumns = ({ locale }) => {
  const consumerPhoneValue = {
    field: 'customer_phone',
    headerName: locale.vehicleDetailTabConsumerPhone,
    exportFilter: exportFilterValidValue,
  };

  const guaranteedPrice = {
    field: 'guaranteed_price',
    headerName: locale.leadTableGuaranteePrice,
    exportFilter: exportFilterValidValue,
  };

  let originalColumns = getLeadListColumns({ locale });
  if (isGuaranteedPriceEnabled) {
    originalColumns.splice(2, 0, consumerPhoneValue, guaranteedPrice);
  } else originalColumns.splice(2, 0, consumerPhoneValue);

  return originalColumns;
};

const TextComponent = styled(Text)`
  color: ${({ theme, props }) =>
    props?.textColor || theme.color.GlobalText.tertiaryColor} !important;
`;

const RenderTextComponent = ({
  size = 'xs',
  weight = 'bold',
  color = 'gray-text',
  content,
}) => {
  return (
    <Text size={size} weight={weight} color={color} marginBottom="2xs">
      {content}
    </Text>
  );
};

const LeadStatus = ({ locale, status }) => {
  if (status === null || status === undefined) return null;
  return (
    <Box
      isFlex
      align="center"
      justify="space-around"
      color={
        status === locale.vehicleOfferAccepted ? 'success-light' : 'error-light'
      }
      radius="md"
      padding="2xs"
    >
      {status === locale.vehicleOfferAccepted ? (
        <>
          <IconDone size="sm" color="primary-success" />
          <Text size="xs" style={{ color: '#4D7A14' }}>
            {locale.statusAccepted}
          </Text>
        </>
      ) : (
        <>
          <IconRefresh size="sm" />
          <Text size="xs">{locale.statusExpired}</Text>
        </>
      )}
    </Box>
  );
};
