import { useLazyQuery as useLazyQueryAC } from '@apollo/client';

import { useAlert } from './useAlert';
import { locale } from '@portal/locale';

/**
 * Integrate error handling and alert notification on apollo client useLazyQuery
 * hook
 */
export const useLazyQuery = (
  query,
  { successText, errorText, onError, onCompleted, ...props } = {}
) => {
  const { setSuccessAlert, setErrorAlert } = useAlert();

  const handleComplete = (data) => {
    if (data) {
      // Some queries denote action therefore allow success prompts if intended
      if (successText) {
        setSuccessAlert(successText);
      }

      onCompleted?.(data);
    }
  };

  /** List of shared error keys that will always map to `api error message` */
  const SHARED_ERROR_KEYS = [locale.customerUnsubscribedMessage];

  /** Static Customized error mappings */
  const STATIC_ERROR_MAP = {
    [locale.validatePhoneNumberMessage]:
      locale.vehicleDetailEditPhoneValidation,
  };

  /**
   * Generates a shared error map dynamically based on `errorMsg`. This ensures
   * that multiple error keys can share the same error message.
   */
  const getSharedErrorMap = (errorMsg) =>
    SHARED_ERROR_KEYS.reduce((acc, key) => ({ ...acc, [key]: errorMsg }), {});

  const handleError = (error) => {
    const errorMsg = error?.message?.toLowerCase() || '';
    if (!errorMsg) {
      setErrorAlert(errorText);
      onError?.(error);
      return;
    }
    const SHARED_ERROR_MAP = getSharedErrorMap(errorMsg);
    const errorMap = { ...STATIC_ERROR_MAP, ...SHARED_ERROR_MAP };

    const matchedError = Object.keys(errorMap).find((key) =>
      errorMsg.includes(key)
    );
    setErrorAlert(matchedError ? errorMap[matchedError] : errorText);
    onError?.(error);
  };

  const [func, { loading: isLoading, ...data }] = useLazyQueryAC(query, {
    ...props,
    onCompleted: handleComplete,
    onError: handleError,
  });

  const funcWithError = async (params) => {
    try {
      const res = await func(params);
      return res;
    } catch (e) {
      handleError(e);
    }
  };

  return [funcWithError, { isLoading, ...data }];
};
