import React, { useState, useRef, useEffect, useCallback } from 'react';
import { SwipeableDrawer, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BottomDrawer = ({
  isOpen,
  title,
  onClose,
  children,
  collapsedHeight = 30,
  expandedHeight = 90,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (contentRef.current?.scrollTop > 10) setIsExpanded(true);
  }, []);

  useEffect(() => {
    const content = contentRef.current;
    if (isOpen && content) content.addEventListener('scroll', handleScroll);
    return () => content?.removeEventListener('scroll', handleScroll);
  }, [isOpen, handleScroll]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      disableSwipeToOpen
      disableSwipeToClose
      PaperProps={{
        sx: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          maxHeight: `${expandedHeight}vh`,
          height: isExpanded ? `${expandedHeight}vh` : `${collapsedHeight}vh`,
          transition: 'height 0.2s ease-in-out',
          overflow: 'hidden',
        },
      }}
    >
      <Box
        p={2}
        pl={2.5}
        sx={{
          position: 'relative',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: '#FAFAFA',
          borderBottom: '1px solid #E0E0E0',
        }}
        onClick={() => setIsExpanded(true)}
      >
        {!isExpanded && (
          <Box
            sx={{
              width: 36,
              height: 5,
              backgroundColor: '#E0E0E0',
              borderRadius: 2,
              margin: '0 auto',
            }}
          />
        )}
        <Box display="flex" alignItems="baseline">
          <Typography
            variant="subtitle1"
            align="left"
            color="#00558C"
            fontWeight={700}
          >
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              marginLeft: 'auto',
              borderRadius: '50%',
              backgroundColor: '#E8E9EB',
              width: 30,
              height: 30,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        ref={contentRef}
        p={2}
        sx={{ height: 'calc(100% - 80px)', overflowY: 'auto' }}
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default BottomDrawer;
