import { useState } from 'react';
import { restApi } from '@portal/api';
import { useVehicleTaxonomy } from './useVehicleTaxonomy';

export const useFetchVehiclePhoto = () => {
  const [photoSrc, setPhotoSrc] = useState('');
  const [taxonomySquishVin, setTaxonomySquishVin] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch vehicle taxonomy for squish vin
  const {
    fetchVehicleTaxonomy,
    isLoading: isVehicleTaxonomyLoading,
    error: vehicleTaxonomyError,
  } = useVehicleTaxonomy();

  // Fetch photo using Vin or YMM
  const getVehiclePhoto = async ({ make, model, style, trim, vin, year }) => {
    setIsLoading(true);

    try {
      if (!vin) {
        // Call taxonomy first for the squishVin given the year
        const taxonomyResponse = await fetchVehicleTaxonomy(year);

        const acvSquishVin =
          taxonomyResponse.data?.[make]?.[model]?.[trim]?.[style] ?? '';

        setTaxonomySquishVin(acvSquishVin);
      }

      const requestedVin = taxonomySquishVin || vin;
      // Fetch photo
      const photoResponse = await restApi.get(
        `/DataOneVinDecode/${requestedVin}`
      );
      if (requestedVin && photoResponse) {
        const vinKey = `VIN: ${requestedVin}`;
        const responseData = photoResponse?.data?.query_responses?.[vinKey];
        const errorMessage = responseData?.query_error?.error_message || null;

        const vehicle = responseData?.us_market_data?.us_styles;
        const photoPath =
          vehicle?.[0]?.media?.evox_stills_single_640px?.[0]?.fullsize_path;
        const photo = photoPath ? `https://${photoPath}` : undefined;

        setPhotoSrc(photo);
        setError(!!errorMessage ? errorMessage : null);
      }
    } catch (error) {
      setPhotoSrc(null);
      setError(error);
    }

    setIsLoading(false);
  };

  const isLoadingVehiclePhoto = isLoading || isVehicleTaxonomyLoading;

  return {
    getVehiclePhoto,
    isLoadingVehiclePhoto,
    taxonomySquishVin,
    vehiclePhotoError: error || vehicleTaxonomyError,
    vehiclePhotoSrc: photoSrc,
  };
};
