import {
  useState,
  useEffect,
  isValidElement,
  useCallback,
  useRef,
} from 'react';
import cls from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { styled } from './lib';
import { useTableActionContext } from './hooks';
import A from './A';
import Flex from './Flex';
import TablePagination from './TablePagination';
import TableLoader from './TableLoader';
import TablePlaceholder from './TablePlaceholder';
import {
  ClientSideRowModelModule,
  ValidationModule,
  ModuleRegistry,
  RowStyleModule,
  CustomFilterModule,
  PaginationModule,
  RowSelectionModule,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
const TableStyle = {
  HeaderRowHeight: 50,
  BodyRowHeight: 75,
};

// In the columns param of the Table Component should be especified in the field name, the syntax of the table query.
// The nested fields are separated by double underscore.
// As example:
// columns: [ { field: 'created_at', headerName: locale.leadTableCreatedAt },{ field: 'contact__mobile', headerName: locale.leadTablePhone }]
// The created_at field should be a real field in the database, that will represents a query like { created_at: "someInfo" }
// The contact__mobile field should be a nested field. that will represent something like: { contact: { mobile: "someInfo" } }

const Table = ({
  columns,
  fullWidthRowRenderer,
  getRowUrl,
  hasPagination = true,
  height,
  isLoading = false,
  isLoadingCount = false,
  limit = 10,
  minHeight,
  onCellClick,
  onLimitChange,
  onNextPageClick,
  onPrevPageClick,
  page = 1,
  pageLabel = 'Rows per page',
  pageStatsText = '{0}-{1} of {2}',
  placeholderText = 'No leads available',
  placeholderOtherText = '',
  rows,
  sortable = true,
  total = 0,
  width,
  ...props
}) => {
  const gridRef = useRef();
  const [internalApi, setInternalApi] = useState(null);
  const { setApi } = useTableActionContext();
  // const prevTotal = usePrevious(total);

  useEffect(() => {
    const timer = handleTableOverlay();

    return () => clearTimeout(timer);
  }, [internalApi, total, isLoading, gridRef]);

  const handleAgGridReady = ({ api }) => {
    setApi(api);
    setInternalApi(api);
  };

  const handleTableOverlay = () => {
    if (!internalApi) {
      return;
    }
    // internalApi.hideOverlay();

    if (isLoading) {
      // const time = !prevTotal ? 0 : 100;
      // return setTimeout(() => internalApi.showLoadingOverlay(), time);
    } else if (!isLoading && total === 0) {
      // internalApi.showNoRowsOverlay();
      return;
    }
  };

  const autoSizeAll = (skipHeader) => {
    if (!!gridRef?.current?.columnApi && !isLoading && !!columns.length) {
      const allColumnIds = [];
      gridRef?.current?.columnApi?.getAllColumns()?.forEach((column) => {
        if (!column.flex) {
          allColumnIds.push(column.getId());
        }
      });
      gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }
  };

  const getRowHeight = (node) => {
    const { data } = node;

    if (data.isHidden) {
      return 0;
    }

    return data.height || TableStyle.BodyRowHeight;
  };

  const getRowClass = (node) => {
    const { data } = node;

    return cls('table__row', {
      'table__row--hidden': data.isHidden,
      'table__row--read': data?.lead_viewed,
    }).split(' ');
  };

  const checkFullWidthRow = useCallback((params) => {
    return Boolean(params?.rowNode?.data?.isFullWidthRow);
  }, []);

  const shouldRedirectOnClick = Boolean(getRowUrl);

  const renderCellValueProperties = useCallback(
    (param) => {
      // param.value could be an string, element or funcion: (param) => <Element />
      const isCallback = typeof param.value === 'function';
      const renderComponent = isCallback ? param.value(param) : param.value;
      const style = param?.colDef?.style || {};

      if (shouldRedirectOnClick) {
        return (
          <A
            className="table__cell-anchor"
            href={getRowUrl(param)}
            isPlaceholder
            onClick={() => onCellClick(param)}
            sx={{ ...style }}
          >
            {isCallback ? (
              renderComponent
            ) : (
              <Typography variant="string" sx={{ ...style }}>
                {renderComponent}
              </Typography>
            )}
          </A>
        );
      }
      if (isValidElement(param.value) || isCallback) {
        return renderComponent;
      }

      return (
        <Typography variant="string" sx={{ ...style }}>
          {renderComponent}
        </Typography>
      );
    },
    [shouldRedirectOnClick]
  );

  const defaultColDef = {
    sortable: sortable,
    cellRenderer: renderCellValueProperties,
  };

  const gridOptions = {
    theme: 'legacy',
  };

  const renderedPagination = hasPagination && (
    <TablePagination
      isLoading={isLoadingCount}
      limit={limit}
      onLimitChange={onLimitChange}
      onNextPageClick={onNextPageClick}
      onPrevPageClick={onPrevPageClick}
      page={page}
      pageLabel={pageLabel}
      pageStatsText={pageStatsText}
      total={total}
    />
  );

  return (
    <Flex
      direction="column"
      height={height}
      minHeight={minHeight}
      width={width}
    >
      <Flex justifyContent="flex-end" marginBottom="15px">
        {renderedPagination}
      </Flex>
      <Flex className="ag-theme-alpine" flexDirection="column" height="100%">
        <AgGridReactStyled
          {...props}
          loading={isLoading}
          columnDefs={columns}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          fullWidthCellRenderer={fullWidthRowRenderer}
          getRowClass={getRowClass}
          getRowHeight={getRowHeight}
          headerHeight={TableStyle.HeaderRowHeight}
          isFullWidthRow={checkFullWidthRow}
          loadingOverlayComponent={TableLoader}
          noRowsOverlayComponent={TablePlaceholder}
          noRowsOverlayComponentParams={
            !isLoading &&
            !isLoadingCount && {
              placeholderText,
              otherText: placeholderOtherText,
            }
          }
          onCellClicked={!shouldRedirectOnClick && onCellClick}
          onGridReady={handleAgGridReady}
          onRowValueChanged={autoSizeAll.bind(this, false)}
          pagination={Boolean(rows.length) && hasPagination}
          ref={gridRef}
          rowData={rows}
          suppressColumnVirtualisation
          suppressPaginationPanel
          modules={[
            ModuleRegistry,
            RowStyleModule,
            RowSelectionModule,
            ClientSideRowModelModule,
            ValidationModule,
            CustomFilterModule,
            PaginationModule,
          ]}
        />
      </Flex>
      <Flex justifyContent="flex-end" marginTop="15px">
        {renderedPagination}
      </Flex>
    </Flex>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.string.isRequired,
};

export default Table;

const AgGridReactStyled = styled(AgGridReact)`
  .ag-root-wrapper {
    border: 1px solid ${({ theme }) => theme.color.raw.gray1};
  }

  .ag-header {
    background: ${({ theme }) => theme.color.ACVBgLm.background1Color};
    border-bottom: 1px solid ${({ theme }) => theme.color.raw.gray1};
  }

  .ag-center-cols-container > .table__row--read {
    background-color: ${({ theme }) => theme.color.ACVBgLm.background1Color};
  }

  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ag-header-cell-text {
    color: ${({ theme }) => theme.color.raw.blackAlpha2};
    ${({ theme }) => theme.typography.raw.tableHeader}
  }

  .ag-cell {
    display: flex;
    align-items: center;

    padding-left: 8px;
    padding-right: 8px;

    color: ${({ theme }) => theme.color.raw.blackAlpha2};
    ${({ theme }) => theme.typography.raw.tableCell}

    a[data-placeholder="true"] {
      color: inherit;
      cursor: default;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ag-has-focus .ag-cell.ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
  }

  .table {
    &__row--hidden {
      display: none;
    }
  }
`;
